import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { List, ListItem, ListItemIcon, Checkbox, Typography } from '@material-ui/core'
import { action } from 'mobx'

interface Props {
  options: Array<any>
  values: Array<any>
  property: string
  onChange: (property: string, value: any) => void
  getOptionLabel?: (value: any) => string
}

@observer
class MultiSelect extends Component<Props> {
  getOptionLabel = (value: any): string => {
    const { getOptionLabel } = this.props
    return getOptionLabel ? getOptionLabel(value) : value
  }

  @action.bound handleToggle = (value: any): void => {
    const { property, values, onChange } = this.props
    const exists = values.indexOf(value) >= 0

    let newValues

    if (exists) {
      newValues = values.filter((v: any) => v !== value)
    } else {
      newValues = [...values, value]
    }

    onChange(property, newValues)
  }

  render() {
    const { options = [], values = [] } = this.props

    return (
      <List>
        {options.map(value => {
          return (
            <ListItem key={value} role={undefined}>
              <ListItemIcon>
                <Checkbox
                  color={'primary'}
                  onClick={() => { this.handleToggle(value) }}
                  checked={values.indexOf(value) !== -1}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <Typography variant={'body1'}>{this.getOptionLabel(value)}</Typography>
            </ListItem>
          )
        })}
      </List>
    )
  }
}

export default MultiSelect
