import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'

interface Props {
  property: string
  value: any
  label: string
  componentProps?: any
  disabled?: boolean
  onChange?: (property: string, value: any) => void
}

@observer
class FormToggle extends Component<Props> {
  @action.bound onChange = (event: any): void => {
    const { property, onChange } = this.props
    const value = event.target.checked
    onChange && onChange(property, value)
  }

  render() {
    const { value, label, disabled, componentProps = {} } = this.props

    return (
      <>
        <FormControlLabel
          control={<Switch
            checked={value}
            onChange={this.onChange}
          />}
          labelPlacement='start'
          label={label}
          disabled={disabled}
          className='formToggle'
          {...componentProps}
        />
        {styles}
      </>
    )
  }
}

const styles = <style>{`
  .formToggle {
    height: 100%;
    padding-left: 0px;
    margin-left: 0px;
    margin-right: 16px;
  }
`}</style>

export default FormToggle
