import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Checkbox, Input, MenuItem, Select, Typography, Box } from '@material-ui/core'

import regions from '../../config/regions'

interface Props {
  tags: Array<string>
  label: string
  onChange: (newTags: string[]) => void
}

@observer
class SelectTags extends Component<Props> {
  render() {
    const { tags, label, onChange } = this.props

    return <>
      <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

      <Box mt={0.7} />

      <Select
        className={'selectTags'}
        multiple
        value={tags}
        onChange={(e: any) => onChange(e.target.value)}
        input={<Input disableUnderline={true} />}
        renderValue={selected => (selected as string[]).join(', ')}>
        {regions.map(name => (
          <MenuItem key={name} value={name}>
            <Checkbox color={'primary'} checked={tags.indexOf(name) > -1} />
            <Box mr={2} />
            <Typography variant={'body1'}>{name}</Typography>
          </MenuItem>
        ))}
      </Select>
      {styles}
    </>
  }
}

const styles = (
  <style>
    {`
      .selectTags {
        display: flex;
        width: 100%;
        align-items: flex-end;
      }
    `}
  </style>
)

export default SelectTags
