import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { fonts, colors } from '../../theme'
import voca from 'voca'

interface Props {
  model: any
  columns: any
  isDetails?: boolean
  elevation?: boolean
}

@observer
class CustomTable extends Component<Props> {
  render() {
    const { columns, model, isDetails = false, elevation = true } = this.props
    const { items, sortBy, sortDir, changeSort } = model
    const style = isDetails ? { borderTopRightRadius: 0, borderTopLeftRadius: 0, paddingTop: 12 } : {}

    return (
      <>
        <Paper className={'tableRoot'} elevation={elevation ? 2 : 0} square={false} style={style}>
          <Table className={'table'}>
            <TableHead>
              <tr>
                {columns.map((column: any) => {
                  return (
                    <TableCell className={'tableTitle'} key={column.key} align={column.align ? column.align : 'left'}>
                      {column.sortable
                        ? <TableSortLabel
                          className={'sortable'}
                          active={sortBy === column.sortable}
                          direction={sortDir === -1 ? 'asc' : 'desc'}
                          onClick={() => { changeSort(column.sortable) }}
                        >
                          {column.title || voca.capitalize(column.key)}
                        </TableSortLabel>
                        : <>{column.title || voca.capitalize(column.key)}</>
                      }
                    </TableCell>
                  )
                })}
              </tr>
            </TableHead>
            <TableBody id={'customTable'}>
              {items && items.map && items.map((row: any, i: number) => (
                <TableRow key={i}>
                  {columns.map((column: any, ii: number) => {
                    return (<TableCell key={column.key + i + ii} className={'tableContent'} align={column.align ? column.align : 'left'}>
                      {column.component
                        ? column.component(row) || '-'
                        : row[column.key] && row[column.key].length > 0 ? row[column.key] : '-'
                      }
                    </TableCell>)
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {styles}
      </>
    )
  }
}

const styles = (
  <style>
    {`
      .tableRoot {
        width: 100%;
        overflow-x: auto;
      }
      .sortable {
        text-decoration: underline;
      }
      .table {
        min-width: 650px;
      }
      .tableTitle {
        font-family: ${fonts.muli};
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
        color: ${colors.INK900};
      }
      .tableContent {
        font-family: ${fonts.muli};
        font-size: 1rem;;
        font-weight: 200;
        line-height: 1.5;
        color: ${colors.INK900};
        white-space: pre-line;
      }
    `}
  </style>
)

export default CustomTable
