import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@material-ui/core'

import FormPriceField from './FormPriceField'
import FormTextField from './FormTextField'
import LoadingOverlay from './LoadingOverlay'
import Row from './Row'
import { penceToPounds } from '../../shared-lib/currency'

interface Props {
  visible: boolean
  onClose: () => void
  onSubmit: (amount: number, note: string) => void
  maxAmount: number
}

@observer
class RefundDialog extends Component<Props> {
  @observable amount = 0
  @observable note = ''
  @observable loading = false

  @action.bound setAmount = (_key: string, amount: number) => {
    this.amount = amount
  }

  @action.bound setNote = (_key: string, note: string) => {
    this.note = note
  }

  @action.bound submit = () => {
    if (this.loading) return
    this.loading = true
    this.props.onSubmit(this.amount, this.note)
  }

  @action.bound reset = () => {
    this.amount = this.props.maxAmount
    this.note = ''
    this.loading = false
  }

  render() {
    const { visible, onClose, maxAmount } = this.props

    return <Dialog open={visible} onEnter={this.reset} fullWidth onClose={onClose}>
      <DialogTitle>{`Refund Payment (£${penceToPounds(maxAmount)})`}</DialogTitle>

      <DialogContent>
        <FormPriceField
          label='Amount'
          value={this.amount}
          property='amount'
          onChange={this.setAmount}
        />
        <Box mt={2} />
        <FormTextField
          label='Note'
          value={this.note}
          property='note'
          onChange={this.setNote}
        />
      </DialogContent>

      <DialogActions>
        <Row>
          <Button variant={'text'} color={'primary'} onClick={onClose}>Cancel</Button>
          <Button variant={'contained'} color={'primary'} onClick={this.submit} disabled={this.amount <= 0 || this.amount > maxAmount}>Refund</Button>
        </Row>
      </DialogActions>

      {this.loading && <LoadingOverlay />}

    </Dialog>
  }
}

export default RefundDialog
