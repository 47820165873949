import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from '@material-ui/core'

import FormPriceField from './FormPriceField'
import FormTextField from './FormTextField'
import LoadingOverlay from './LoadingOverlay'
import Row from './Row'
import { Alert } from '@material-ui/lab'
import FormSelect from './FormSelect'
import creditCategories from '../../config/creditCategories'
import { $Credit } from '../../types'
import { penceToPounds } from '../../shared-lib/currency'

interface Props {
  visible: boolean
  userBalance: number
  onClose: () => void
  onSubmit: (credit: $Credit) => void
}

const defaultCredit: $Credit = {
  amount: 0,
  note: '',
  category: '',
}

@observer
class AddCreditDialog extends Component<Props> {
  @observable credit: $Credit = { ...defaultCredit }

  @observable error = false
  @observable loading = false

  @action.bound onChange = (key: string, value: number | string) => {
    (this.credit as any)[key] = value
    this.error = false
  }

  @action.bound submit = () => {
    if (this.loading) return

    const { amount } = this.credit

    if ((amount < 0 && Math.abs(amount) > this.props.userBalance)) {
      this.error = true
      return
    }

    this.loading = true
    this.props.onSubmit(this.credit)
  }

  @action.bound reset = () => {
    this.credit = { ...defaultCredit }
    this.error = false
    this.loading = false
  }

  render() {
    const { visible, onClose, userBalance } = this.props
    const { amount, note, category } = this.credit
    const buttonText = amount < 0 ? 'Remove Credit' : 'Add Credit'

    return <Dialog open={visible} onEnter={this.reset} fullWidth onClose={onClose}>
      <DialogTitle>{'Add/Remove credit'}</DialogTitle>
      <DialogContent>
        <FormPriceField
          label='Amount'
          value={amount}
          property='amount'
          onChange={this.onChange}
        />

        <Box mt={1} mb={1}>
          <FormSelect
            label='Category'
            value={category}
            property='category'
            placeholder='Unknown'
            options={creditCategories}
            onChange={this.onChange}
          />
        </Box>

        <FormTextField
          label='Note'
          value={note}
          property='note'
          onChange={this.onChange}
        />

        {this.error && (
          <Box mt={2}>
            <Alert severity='error'>
              <Typography>You cannot have a negative credit balance. The maximum amount you can withdraw is £{penceToPounds(userBalance)}</Typography>
            </Alert>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Row>
          <Button variant={'text'} color={'primary'} onClick={onClose}>Cancel</Button>
          <Button onClick={this.submit} variant={'contained'} color={'primary'} disabled={amount === 0 || category.length === 0}>{buttonText}</Button>
        </Row>
      </DialogActions>

      {this.loading && <LoadingOverlay />}
    </Dialog>
  }
}

export default AddCreditDialog
