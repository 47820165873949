import React, { FunctionComponent } from 'react'
import { Card } from '@material-ui/core'

const PaddedCard: FunctionComponent = (props: any) => (
  <Card className={'card'}>
    {props.children}
    {styles}
  </Card>
)

const styles = (
  <style>
    {`
      .card {
        padding: 24px
      }
    `}
  </style>
)

export default PaddedCard
