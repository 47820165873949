import { observer } from 'mobx-react'
import React, { Component } from 'react'
import voca from 'voca'
import { observable } from 'mobx'
import {
  Paper,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core'
import { colors, fonts } from '../../theme'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

interface Props {
  model: any
  sectionKey: any
  dataColumns: any
  isDetails?: boolean
  headerComponent?: any | undefined
}

@observer
class SectionTable extends Component<Props> {
  @observable collapseIndexes: Array<number> = []

  // Collapse index
  collapseSection = (index: number) => {
    if (this.collapseIndexes.includes(index)) {
      this.collapseIndexes = this.collapseIndexes.filter((i: number) => i !== index)
    } else {
      this.collapseIndexes.push(index)
    }
  }

  render() {
    const { sectionKey, dataColumns, model, isDetails = false, headerComponent: HeaderComponent } = this.props
    const { groupedItems } = model
    const style = isDetails ? { borderTopRightRadius: 0, borderTopLeftRadius: 0, paddingTop: 12 } : {}

    return (
      <>
        <Paper className={'sectionTableRoot'} elevation={2} square={false} style={style}>
          {groupedItems.map((row: any, i: number) => {
            const data = row.data || row.items
            return (
              <Collapse
                key={i}
                className={'sectionCollapse'}
                collapsedHeight={'51px'}
                in={this.collapseIndexes.includes(i)}
              >
                <div key={i} className={'sectionTableTitleContainer'} onClick={() => this.collapseSection(i)}>
                  <Box mt={0.5} ml={2} mr={1} color={colors.PRI900}>
                    {this.collapseIndexes.includes(i) ? <ExpandLess color={'inherit'} /> : <ExpandMore color={'inherit'} />}
                  </Box>
                  { /* TODO This could be passed from the parent component and remove the conditional here */}
                  {!this.props.headerComponent ? (
                    <Typography variant={'body2'} className={'sectionTableTitle'}>{row[sectionKey]}</Typography>
                  ) : React.createElement(HeaderComponent, row)}
                </div>

                <Table>
                  <TableHead className={'subTable'}>
                    <tr>
                      {dataColumns.map((dataColumn: any) => {
                        return (
                          <TableCell key={dataColumn.key} className={'tableTitle'}>
                            {dataColumn.title || voca.capitalize(dataColumn.key)}
                          </TableCell>
                        )
                      })}
                    </tr>
                  </TableHead>
                  <TableBody className={'subTable'}>
                    {data.map((dataRow: any, index: number) => (
                      <TableRow key={i + index}>
                        {dataColumns.map((dataColumn: any) => (
                          <TableCell key={dataColumn.key + i + index} className={'tableContent'} align={'left'}>
                            {dataColumn.component
                              ? dataColumn.component(dataRow)
                              : dataRow[dataColumn.key]
                            }
                          </TableCell>),
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            )
          },
          )}
        </Paper>

        {styles}
      </>
    )
  }
}

const styles = (
  <style>
    {`
      .sectionTableRoot {
        width: 100%;
        overflow-x: auto;
      }
      .sectionCollapse {
        width: 100%;
      }
      .sectionTableTitleContainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid ${colors.LINE};
        cursor: pointer;
      }
      .sectionTableTitle {
        font-weight: bold;
        overflow-x: auto;
        width: 100%;
        padding: 12px;
      }
      .subTable {
        background-color: ${colors.BG300};
      }
      .tableTitle {
        font-family: ${fonts.muli};
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
        color: ${colors.INK900};
      }
      .tableContent {
        font-family: ${fonts.muli};
        font-size: 1rem;;
        font-weight: 200;
        line-height: 1.5;
        color: ${colors.INK900};
      }
    `}
  </style>
)

export default SectionTable
