import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import FormSelect from './FormSelect'
import { $Filter } from '../../types'
import { observer } from 'mobx-react'

interface Props {
  filters: Array<$Filter>
  onChangeFilter: (property: string, value: string) => void
}

@observer
class Filters extends Component<Props> {
  render() {
    const { filters, onChangeFilter } = this.props

    return (
      <Grid container spacing={1}>
        {filters.map((filter: $Filter) => {
          return (
            <Grid item xs={4} key={filter.property}>
              <FormSelect
                label={filter.label}
                value={filter.value}
                property={filter.property}
                placeholder={''}
                options={filter.options}
                getOptionLabel={filter.getOptionLabel}
                onChange={onChangeFilter}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

export default Filters
