import React, { Component } from 'react'
import { Paper, Tab, Tabs as MaterialTabs } from '@material-ui/core'

import { observer } from 'mobx-react'

interface Props {
  items: any
  onPress: (item: number) => void
}

@observer
class Tabs extends Component<Props> {
  // On press filter
  applyFilter = (item: any, title: number) => {
    // Select right item
    const { items } = this.props
    const oldTab = items.find((i: any) => i.selected)
    if (oldTab) oldTab.selected = false

    const newTabIndex = items.findIndex((i: any) => i.title === title)
    if (newTabIndex > -1) items[newTabIndex].selected = true

    // Trigger onPress prop
    this.props.onPress(newTabIndex)
  }

  render() {
    const { items } = this.props
    const selectedItem = items.find((i: any) => i.selected)

    return (
      <Paper elevation={2} style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <MaterialTabs
          variant={'scrollable'}
          value={selectedItem.title}
          indicatorColor={'primary'}
          textColor={'primary'}
          onChange={this.applyFilter}
        >
          {items.map((item: any, index: any) => {
            const selected = selectedItem.title === item.title

            return (
              <Tab key={index} textColor={'primary'} label={item.title} value={item.title} selected={selected} />
            )
          })}
        </MaterialTabs>
      </Paper>

    )
  }
}

export default Tabs
