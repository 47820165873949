import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, InputAdornment, TextField, Typography } from '@material-ui/core'

interface Props {
  property?: string
  value: any
  label: string
  errorText?: string
  componentProps?: any
  disabled?: boolean
  onChange: (property: string, value: any) => void
}

@observer
class FormPriceField extends Component<Props> {
  @observable showNegativeIndicator = false

  @action.bound onChange = (event: any): void => {
    const { property, onChange, value } = this.props

    const str = event.target.value

    if (str === '') {
      property && onChange(property, '')
      this.showNegativeIndicator = false
      return
    }

    if (str === '-') {
      property && onChange(property, '')
      this.showNegativeIndicator = true
      return
    }

    this.showNegativeIndicator = false
    const newValue = Math.round(parseFloat(event.target.value) * 100)

    newValue !== value && property && onChange(property, newValue)
  }

  @action.bound onBlur = (): void => {
    const { property, onChange, value } = this.props
    if (isNaN(value) || value === '' || value === undefined || value === null) {
      property && onChange(property, 0)
    }
  }

  @computed get displayValue() {
    const { value } = this.props

    if (this.showNegativeIndicator) return '-'
    if (value === '') return ''

    return (value / 100)
  }

  render() {
    const { label, property, errorText = '', componentProps = {}, disabled } = this.props

    const error = errorText.length > 0

    return (
      <>
        <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

        <Box mt={0.7} />

        <TextField
          type='number'
          key={label + property}
          name={property}
          margin={'normal'}
          autoComplete={'off'}
          variant={'standard'}
          fullWidth
          disabled={disabled}
          error={error}
          helperText={errorText}
          value={this.displayValue}
          onBlur={this.onBlur}
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment position='start'>£</InputAdornment>,
            endAdornment: null,
          }}
          onChange={this.onChange}
          {...componentProps}
        />

      </>
    )
  }
}

export default FormPriceField
