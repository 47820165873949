import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Button, Dialog, DialogActions } from '@material-ui/core'
import { observable } from 'mobx'

interface Props {
  images: Array<string>
  indexToShow: number
  visible: boolean
  onClose: () => void
}

@observer
class ImageDialog extends Component<Props> {
  @observable showingIndex = 0

  showNext = () => {
    if (this.showingIndex + 1 === this.props.images.length) {
      this.showingIndex = 0
    } else {
      this.showingIndex += 1
    }
  }

  onEnter = () => {
    const { indexToShow } = this.props
    this.showingIndex = indexToShow
  }

  render() {
    const { visible, onClose, images } = this.props
    const src = images[this.showingIndex]

    return <Dialog fullScreen open={visible} onEnter={this.onEnter} >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={2}>
        <img src={src} style={{ maxWidth: '90%', maxHeight: '85vh' }} />

        <DialogActions>
          <Button onClick={this.showNext}>Next</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  }
}

export default ImageDialog
