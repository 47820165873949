import debounce from 'debounce-promise'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface Props {
  options: Array<any>
  refreshList?: (search: string) => void
  onAdd: (_id: string) => void
  getOptionLabel?: (option: any) => string
  showSelectedOption?: boolean
  label: string
}

@observer
class SearchFieldAutoComplete extends Component<Props> {
  // Create ref of field to be able to blur it
  @observable search = ''
  debounced_refreshList: any

  componentDidMount() {
    const { refreshList } = this.props

    if (refreshList) this.debounced_refreshList = debounce(refreshList, 200)
  }

  @action.bound onChange = (event: any) => {
    this.search = event.target.value

    if (this.search.length > 2) {
      this.debounced_refreshList && this.debounced_refreshList(this.search)
    }
  }

  // On list item selected
  @action.bound onSelect = (event: any, option: any) => {
    const { onAdd } = this.props

    event.target.blur()

    // Empty search
    this.search = this.props.showSelectedOption ? this.getOptionLabel(option) : ''

    // Add auto complete item
    onAdd(option)
  }

  getOptionLabel = (option: any) => {
    const { getOptionLabel } = this.props
    return (getOptionLabel && getOptionLabel(option)) || option
  }

  render() {
    const { options, label } = this.props

    const displayOptions = this.search.length > 0 ? options : []

    return (
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

        <Box mt={0.7} />

        <Autocomplete
          options={displayOptions}
          getOptionLabel={this.getOptionLabel}
          freeSolo
          clearOnEscape
          disableClearable
          autoComplete
          inputValue={this.search}
          onChange={this.onSelect}
          onOpen={this.onChange}
          renderInput={(params: any) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              type={'text'}
              margin={'normal'}
              autoComplete={'off'}
              variant={'standard'}
              fullWidth
              onChange={this.onChange}
            />
          )}
        />
      </Box>
    )
  }
}

export default SearchFieldAutoComplete
