import React from 'react'

import { Box, createStyles, withStyles } from '@material-ui/core'

interface Props {
  classes: Record<string, string>
  onClick: (e?: any) => void
  children?: React.ReactNode;
}

const styles = () => createStyles({
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
})

const Clickable = (props: Props) => {
  const { onClick, children, classes } = props

  return <Box onClick={onClick} className={classes.clickable}>
    {children}
  </Box>
}

export default withStyles(styles)(Clickable)
