import { observer } from 'mobx-react'
import Link from 'next/link'
import React, { Component } from 'react'

import { Typography } from '@material-ui/core'

interface Props {
  value: any
  label: string
  errorText?: string
  href?: string
  as?: string
  extraComponent?: any
}

@observer
class FormInfo extends Component<Props> {
  render() {
    const { value, label, href, as, extraComponent, errorText } = this.props

    return (
      <div className={`container ${errorText && 'error'}`}>
        <Typography className='label' variant='caption' >{label}</Typography>

        <div className='inner'>
          {
            href
              ? <Link href={href} as={as}><a><Typography variant='body1'>{value}</Typography></a></Link>
              : <Typography variant='body1'>{value}</Typography>
          }

          {extraComponent}
        </div>

        {!!errorText && <Typography className='errorMessage' variant='caption'>{errorText}</Typography>}

        {styles}
      </div>
    )
  }
}

const styles = (
  <style>
    {`
      .container  {
        padding-top: 13px;
      }

      .container .label {
        font-size: 12px;
      }

      .container.error .label, .container.error .errorMessage {
        color: red;
      }

      .container a {
        cursor: pointer;
      }

      .container .inner {
        margin-top: 3px;
        display: flex;
        flex-direction: row;
        background: none;
      }
    `}
  </style>
)

export default FormInfo
