import React from 'react'

import { Box, createStyles, Typography, withStyles } from '@material-ui/core'

import { colors } from '../../theme'

interface Props {
  classes?: any
  selected?: boolean
  addText?: string
}

const styles = (theme: any) => createStyles({
  add: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: theme.spacing(6),
    borderRadius: theme.spacing(3) + 1,
    border: `2px solid ${colors.PRI200}`,
  },
  unselected: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    border: `2px solid ${colors.PRI200}`,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    backgroundColor: colors.PRI800,
    border: `2px solid ${colors.PRI800}`,
    boxShadow: `0 0 7px ${colors.PRI800}`,
    '& img': {
      width: 15,
      height: 15,
    },
  },
})

const TickBox = (props: Props) => {
  const { selected = false, addText = '', classes } = props

  if (selected) {
    return <Box className={classes.selected}>
      <img src='/img/icons/tickWhite.png' alt='Tick' />
    </Box>
  }

  if (addText) {
    return <Box className={classes.add}>
      <Typography variant='body1' color='primary'>{addText}</Typography>
    </Box>
  }

  return <Box className={classes.unselected} />
}

export default withStyles(styles)(TickBox)
