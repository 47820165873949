import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  toastData: {
    message: string
    open: boolean
    type?: string
  },
  hideToast: () => void
}

@observer
class Toast extends Component<Props> {
  render() {
    const { toastData, hideToast } = this.props

    return <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={toastData.open}
        autoHideDuration={6000}
        className={toastData.type}
        message={toastData.message}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={hideToast}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />

      {styles}
    </>
  }
}

const styles = (
  <style>
    {`
      .error>div {
        background-color: #b63838;
      }
      .success>div {
        background-color: #4caf50;
      }
    `}
  </style>
)

export default Toast
