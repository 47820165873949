import React, { Component } from 'react'

import { Box } from '@material-ui/core'

class RowSpacer extends Component {
  render() {
    return <Box width={16} />
  }
}

export default RowSpacer
