import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Checkbox, Input, MenuItem, Select, Typography, Box } from '@material-ui/core'
import { $Category } from '../../types'

interface Props {
  values: Array<any>
  model: any
  label: string
  onChange: (property: string, data: any) => void
}

@observer
class SelectCategories extends Component<Props> {
  // Change categories
  onChangeCategories = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { onChange } = this.props

    onChange('categories', event.target.value)
  }

  render() {
    const { values, label, model: { categories } } = this.props

    return <div>
      <>
        <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

        <Box mt={0.7} />

        <Select
          className={'selectCategories'}
          multiple
          value={values}
          onChange={this.onChangeCategories}
          input={<Input disableUnderline />}
          renderValue={() => values.map((val: string) => val).join(', ')}>
          {categories && categories.map((category: $Category) => {
            const isSelected = values.filter((val: string) => val === category.name).length > 0

            return <MenuItem key={category.name} value={category.name}>
              <Checkbox color={'primary'} checked={isSelected} />
              <Box mr={1} />
              <Typography variant={'body1'}>{category.name}</Typography>
            </MenuItem>
          })}
        </Select>
      </>
      {styles}
    </div >
  }
}

const styles = (
  <style>
    {`
      .selectCategories {
        display: flex;
        width: 100%;
        align-items: flex-end;
      }
    `}
  </style>
)

export default SelectCategories
