import React, { Component } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Close } from '@material-ui/icons'

interface Props {
  model: any
}

@observer
class SearchField extends Component<Props> {
  // On search change
  @action.bound onSearchChange = (event: any): void => {
    this.props.model.search = event.target.value
  }

  // On enter pressed
  @action.bound onEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      // Unfocus field
      event.target.blur()

      // Update search
      const { updateSearch } = this.props.model
      const text = event.target.value.trim()
      updateSearch(text)
    }
  }

  // Clear search
  @action.bound clearSearch = () => {
    const { updateSearch } = this.props.model
    updateSearch('')
  }

  render() {
    const { search } = this.props.model

    return (
      <div className={'searchFieldRoot'}>
        <TextField
          key={'search'}
          type={'string'}
          margin={'normal'}
          autoComplete={'off'}
          variant={'standard'}
          placeholder={'Search'}
          fullWidth
          value={search}
          onChange={this.onSearchChange}
          onKeyPress={this.onEnterPressed}
          InputProps={{
            disableUnderline: true,
            endAdornment:
              search.length
                ? <InputAdornment position='end'>
                  <IconButton onClick={this.clearSearch}>
                    <Close />
                  </IconButton>
                </InputAdornment>
                : null,
          }}
        />
        {styles}
      </div>
    )
  }
}

const styles = <style>{`
  .searchFieldRoot {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 400px;
    margin-left: 40px;
  }
`}</style>

export default SearchField
