import { action, observable, observe } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import UIStore from '../../src/stores/UiStore'
import { FormTextField } from './'

export interface Props {
  uiStore: UIStore
}

@inject('uiStore')
@observer
class GenericPopup extends Component<Props> {
  @observable isVisible = false
  @observable dialog: any = {}
  @observable inputProps: any = {}

  constructor(props: Props) {
    super(props)
    this.observeUiStore()
  }

  // Observe dialog from uiStore
  observeUiStore = () => {
    const { uiStore } = this.props
    observe(uiStore, 'dialog', ({ newValue }) => {
      if ('title' in newValue && 'body' in newValue) {
        this.dialog = { ...newValue }
        this.showModal()
      }
    })
  }

  // Show modal
  showModal = () => {
    this.isVisible = true
  }

  // Hide modal
  hideModal = () => {
    this.isVisible = false

    const { uiStore } = this.props
    uiStore.showMessage({})
  }

  // On press button from list of buttons
  onClick = (button: any, property: string) => {
    if (button && button.onClick) button.onClick()

    // Send back the value of the input
    if (this.inputProps[property]) {
      button.onClick(this.inputProps[property])
    }
    this.hideModal()
  }

  @action.bound onChangeField = (property: string, value: string) => {
    this.inputProps[property] = value
  }

  render() {
    const {
      title = '',
      body = '',
      hasInput = false,
      inputProps = {},
    } = this.dialog
    const buttons: Array<any> = this.dialog.buttons || [{ title: 'Ok', onClick: this.onClick }]

    return (
      <Dialog
        open={this.isVisible}
        onClose={this.hideModal}
        maxWidth={'xs'}
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <Typography style={{ whiteSpace: 'pre-wrap' }} variant={'body1'}>{body}</Typography>

          {hasInput && (
            <FormTextField
              property={inputProps.property}
              label={inputProps.label}
              value={inputProps.value}
              onChange={this.onChangeField}
            />
          )}
        </DialogContent>

        <DialogActions>
          {buttons.map((button: any, index: any) => (
            <Box display={'flex'} mr={index === buttons.length - 1 ? 0 : 2} key={index}>
              <Button key={index} variant={'text'} color={'primary'} onClick={() => this.onClick(button, inputProps.property)} style={{ paddingRight: 0 }}>
                {button.title}
              </Button>
            </Box>
          ))}
        </DialogActions>
      </Dialog>
    )
  }
}

export default GenericPopup
