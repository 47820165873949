import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Button, Dialog, DialogActions, Typography } from '@material-ui/core'

import FormSelect from './FormSelect'
import { penceToPounds } from '../../shared-lib/currency'

interface Props {
  booking: any
  model: any
  visible: boolean
  onClose: () => void
  onSubmit: () => void
}

@observer
class CancellationDialog extends Component<Props> {
  @observable percentage = 0

  // On percentage update
  onChangePercentage = (_property: any, value: any) => {
    this.percentage = parseInt(value.replace('%', ''), 10)
    this.props.model.cancelRefundPercentage = this.percentage
  }

  // Calculate price
  @computed get totalPriceComputed() {
    const { booking } = this.props
    const price = booking.items.reduce((a: number, b: any) => {
      let p = b.treatment.price

      b.treatment.optionGroups.forEach((og: any) => {
        og.options.forEach((o: any) => {
          if (o.selected) p += o.price
        })
      })

      return a + p
    }, 0)

    return price
  }

  render() {
    const { visible, onClose, onSubmit } = this.props
    const refundAmount = penceToPounds(this.totalPriceComputed * this.percentage / 100)
    const title = 'How much do you want to refund ?'
    const body = 'Choose the percentage of the booking price you want to refund.'
    const percentages = ['0%', '50%', '100%']

    return <Dialog open={visible}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} p={2}>
        <Typography variant={'h5'}>{title}</Typography>
        <br />
        <Typography variant={'subtitle1'}>{body}</Typography>

        <FormSelect
          label='Refund percentage'
          value={`${this.percentage}%`}
          property='percentage'
          placeholder='Refund percentage'
          options={percentages}
          onChange={this.onChangePercentage}
        />

        <DialogActions>
          <Button onClick={onClose}>Abort</Button>
          <Button onClick={onSubmit} color='primary'>{`Cancel & refund £${refundAmount}`}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  }
}

export default CancellationDialog
