
import React, { FunctionComponent } from 'react'
import { ArrowBackIos } from '@material-ui/icons'
import { colors } from '../../theme'
import { Box } from '@material-ui/core'
import Router from 'next/router'

const BackLink: FunctionComponent = () => {
  const onClick = () => Router.back()

  return (
    <a onClick={onClick}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={45} height={45} borderRadius={'50%'} color={colors.BG900} bgcolor={colors.BACK_BUTTON} mr={1.5} style={{ cursor: 'pointer' }}>
        <ArrowBackIos color={'inherit'} style={{ marginLeft: 8 }} />
      </Box>
    </a>
  )
}

export default BackLink
