
import React, { FunctionComponent } from 'react'

interface Props {
  height?: number
}

const FormDivider: FunctionComponent<Props> = ({ height = 2 }: Props) => (
  <>
    <div className={'formDivider'} style={{ height: `${height}pt` }} />
    {styles}
  </>
)

const styles = (
  <style>
    {`
      .formDivider {
        width: calc( 100% + 48pt);
        background: #dddddd;
        height: 2pt;
        margin-left: -24pt;
        margin-top: 12pt;
        margin-bottom: 12pt;
      }
    `}
  </style>
)

export default FormDivider
