import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { CircularProgress, Typography } from '@material-ui/core'
import { colors } from '../../theme'

interface Props {
  text?: string
}

@observer
class LoadingOverlay extends Component<Props> {
  render() {
    const { text } = this.props

    return (
      <div className='loadingOverlay'>
        {!!text && <Typography className={'loadingText'} variant={'body1'}>{text}</Typography>}
        <CircularProgress className='loading' />
        {styles}
      </div>
    )
  }
}

const styles = <style> {`
    .loadingOverlay {
      position: absolute;
      width: 100%;
      background: rgba(255, 255, 255, 0.95);
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
    }
    .loading {
      color: ${colors.PRI900};
    }
    .loadingText {
      margin-bottom: 16px;
    }
  `}
</style>

export default LoadingOverlay
