import React, { Component } from 'react'

interface Props {
  alignItems?: any
  justifyContent?: any
  minHeight?: any
}

class Row extends Component<Props> {
  render() {
    const { alignItems = 'center', justifyContent = 'space-between', minHeight = 0 } = this.props

    return (
      <div className={'row'} style={{ minHeight, alignItems, justifyContent }}>
        {this.props.children}
        {styles}
      </div>
    )
  }
}

const styles = <style>{`
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`}</style>

export default Row
